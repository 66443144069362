import { BinIcon, ColorPicker, NeutralButton, NeutralTypeEnum, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { DEFAULT_BG_COLOR, TOOLTIP_STYLES } from '../../constans/default-styles'
import HighlighterIcon from '../../icons/highlighter-icon'
import { isImageSelected } from '../../utils/is-image-selected'

interface Props {
  className?: string
  editor: Editor | null
}
const BackgroundColorButton: FC<Props> = ({ className, editor }) => {
  const { t } = useLocoTranslation()

  const onChangeBackgroundColor = (color: string) => {
    if (!editor) return null

    editor.chain().setBackgroundColor(color).run()
  }

  const unSetBackgroundColor = () => {
    if (!editor) return null

    editor.chain().focus().unsetBackgroundColor().run()
  }

  const color = editor?.getAttributes('textStyle').backgroundColor || DEFAULT_BG_COLOR

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  const hasAppliedColor = !!editor?.getAttributes('textStyle').backgroundColor
  return (
    <div className={className}>
      {!isDisabled ? (
        <ColorPicker
          additionalPopoverChildren={
            <NeutralButton
              disabled={!hasAppliedColor}
              className={'w-fit self-center'}
              neutralType={NeutralTypeEnum.danger}
              onClick={unSetBackgroundColor}
            >
              <BinIcon
                className={twMerge(
                  !hasAppliedColor ? 'fill-darkblue/50' : 'fill-darkblue group-hover:fill-danger',
                  'main-transition-colors w-4 h-4',
                )}
              />
            </NeutralButton>
          }
          color={color}
          onChange={onChangeBackgroundColor}
        >
          {open => (
            <Tooltip label={t('dashboard.simple_mail_editor.background_color')} {...TOOLTIP_STYLES}>
              <div
                className={twMerge(
                  'inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium rounded-lg min-h-[42px] w-[48px]',
                  open
                    ? 'text-white bg-blue hover:bg-blue-300'
                    : 'text-darkblue bg-white hover:bg-white border border-gray/30 hover:border-blue hover:text-blue',
                )}
              >
                <HighlighterIcon
                  style={{
                    fill:
                      color.toString().toLowerCase() !== '#ffffff'
                        ? color
                        : open
                        ? 'white'
                        : 'black',
                  }}
                />
              </div>
            </Tooltip>
          )}
        </ColorPicker>
      ) : (
        <NeutralButton type="button" disabled={isDisabled}>
          <HighlighterIcon className={twMerge(isDisabled && 'fill-gray')} />
        </NeutralButton>
      )}
    </div>
  )
}

export default BackgroundColorButton
