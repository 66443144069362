export const getImageAttrs = (image: HTMLImageElement | null) => {
  const src = image?.getAttribute('src')
  const alt = image?.getAttribute('alt')
  const title = image?.getAttribute('title')
  const width = image?.getAttribute('width')
  const height = image?.getAttribute('height')

  return {
    src,
    alt,
    title,
    width,
    height,
  }
}
