import React from 'react'

function ImageInlineIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path opacity=".5" d="M2 3h16v1.5H2zm11.5 9H18v1.5h-4.5zM2 15h16v1.5H2z"></path>
      <path d="M12.003 7v5.5a1 1 0 0 1-1 1H2.996a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h8.007a1 1 0 0 1 1 1zm-1.506.5H3.5V12h6.997V7.5z"></path>
    </svg>
  )
}

export default ImageInlineIcon
