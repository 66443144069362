import { Command, Extension } from '@tiptap/core'
import { Node } from 'prosemirror-model'
import { AllSelection, TextSelection, Transaction } from 'prosemirror-state'

type IndentOptions = {
  types: string[]
  indentLevels: number[]
  defaultIndentLevel: number
}

declare module '@tiptap/core' {
  interface Commands {
    indent: {
      indent: () => Command
      outdent: () => Command
    }
  }
}

export function clamp(val: number, min: number, max: number): number {
  if (val < min) {
    return min
  }
  if (val > max) {
    return max
  }
  return val
}

export enum IndentProps {
  min = 0,
  max = 140,

  more = 20,
  less = -20,
}

export function isBulletListNode(node: Node): boolean {
  return node.type.name === 'bullet_list'
}

export function isOrderedListNode(node: Node): boolean {
  return node.type.name === 'order_list'
}

export function isListNode(node: Node): boolean {
  return isBulletListNode(node) || isOrderedListNode(node)
}

function setNodeIndentMarkup(tr: Transaction, pos: number, delta: number): Transaction {
  if (!tr.doc) return tr

  const node = tr.doc.nodeAt(pos)
  if (!node) return tr

  const minIndent = IndentProps.min
  const maxIndent = IndentProps.max

  let currentIndent = 0
  if (typeof node.attrs.indent === 'number') {
    currentIndent = node.attrs.indent
  } else if (typeof node.attrs.indent === 'string') {
    currentIndent = parseInt(node.attrs.indent) || 0
  }

  const indent = clamp(currentIndent + delta, minIndent, maxIndent)

  if (indent === currentIndent) return tr

  const nodeAttrs = {
    ...node.attrs,
    indent,
  }

  return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
}

function updateIndentLevel(tr: Transaction, delta: number): Transaction {
  const { doc, selection } = tr

  if (!doc || !selection) return tr

  if (!(selection instanceof TextSelection || selection instanceof AllSelection)) {
    return tr
  }

  const { from, to } = selection

  doc.nodesBetween(from, to, (node, pos) => {
    const nodeType = node.type

    if (nodeType.name === 'paragraph' || nodeType.name === 'heading') {
      tr = setNodeIndentMarkup(tr, pos, delta)
      return false
    }
    if (isListNode(node)) {
      return false
    }
    return true
  })

  return tr
}

export const Indent = Extension.create<IndentOptions>({
  name: 'indent',

  addOptions() {
    return {
      types: ['heading', 'paragraph'],
      indentLevels: [0, 20, 40, 60, 80, 100, 120, 140],
      defaultIndentLevel: 0,
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: this.options.defaultIndentLevel,
            renderHTML: attributes => {
              const indent =
                typeof attributes.indent === 'number'
                  ? attributes.indent
                  : parseInt(String(attributes.indent)) || this.options.defaultIndentLevel

              return {
                style: `margin-left: ${indent}px;`,
              }
            },
            parseHTML: element => {
              const marginLeft = element.style.marginLeft
              let value = this.options.defaultIndentLevel

              if (marginLeft && typeof marginLeft === 'string') {
                const parsed = parseInt(marginLeft.replace(/[^\d.-]/g, ''))
                if (!isNaN(parsed)) {
                  value = parsed
                }
              }

              return {
                indent: value,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      indent:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state
          tr = tr.setSelection(selection)
          tr = updateIndentLevel(tr, IndentProps.more)

          if (tr.docChanged) {
            dispatch && dispatch(tr)
            return true
          }

          return false
        },
      outdent:
        () =>
        ({ tr, state, dispatch }) => {
          const { selection } = state
          tr = tr.setSelection(selection)
          tr = updateIndentLevel(tr, IndentProps.less)

          if (tr.docChanged) {
            dispatch && dispatch(tr)
            return true
          }

          return false
        },
    }
  },
})
