import { Node, NodeWithPos, Tracker, findChildrenInRange, mergeAttributes } from '@tiptap/core'
import { ImageModeEnum, ImageNameEnum } from '../enums/image-enum'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: {
        imageMode?: ImageModeEnum
        src: string
        alt?: string
        title?: string
        width?: number
        height?: number
        containerWidth?: string
      }) => ReturnType
      toggleImageMode: (imageMode: ImageModeEnum) => ReturnType
    }
  }
}

export const Image = Node.create({
  name: 'image',

  addOptions() {
    return {
      inline: false,
    }
  },

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      imageMode: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
      href: {
        default: null,
      },
      containerWidth: {
        default: 'auto',
      },
      showBubbleMenu: {
        default: false,
      },
      caption: {
        default: null,
      },
      uploadingProps: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]:not([src^="data:"])',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setImage:
        options =>
        ({ commands }) => {
          let type

          switch (options.imageMode) {
            case ImageModeEnum.Inline: {
              type = ImageNameEnum.Inline
              break
            }
            case ImageModeEnum.BlockRight:
            case ImageModeEnum.BlockCenter: {
              type = ImageNameEnum.Block
              break
            }
            default: {
              type = 'image'
              break
            }
          }

          return commands.insertContent({
            type,
            attrs: options,
          })
        },
      toggleImageMode:
        (imageMode: ImageModeEnum) =>
        ({ tr, commands }: any) => {
          const { doc, selection } = tr
          const { from, to } = selection

          const items = findChildrenInRange(
            doc,
            { from, to },
            node =>
              node.type.name === ImageNameEnum.Inline || node.type.name === ImageNameEnum.Block,
          )

          if (!items.length) {
            return false
          }

          const tracker = new Tracker(tr)

          return commands.forEach(items, ({ node, pos }: NodeWithPos) => {
            const mapResult = tracker.map(pos)

            if (mapResult.deleted) {
              return false
            }

            const range = {
              from: mapResult.position,
              to: mapResult.position + node.nodeSize,
            }

            const success = commands.insertContentAt(range, {
              type: imageMode === ImageModeEnum.Inline ? ImageNameEnum.Inline : ImageNameEnum.Block,
              attrs: {
                ...node.attrs,
                imageMode,
                caption: imageMode === ImageModeEnum.Inline ? undefined : node.attrs.caption,
                float: imageMode === ImageModeEnum.BlockRight ? 'right' : null,
              },
            })

            const parentPos = mapResult.position - 1

            if (parentPos >= 0) {
              const parentNode = tr.doc.nodeAt(parentPos)

              if (parentNode?.type.name === 'paragraph' && parentNode.nodeSize === 2) {
                tr.delete(parentPos, parentPos + parentNode.nodeSize)
              }
            }

            if (
              imageMode === ImageModeEnum.Inline &&
              (node.attrs.imageMode === ImageModeEnum.BlockCenter ||
                node.attrs.imageMode === ImageModeEnum.BlockRight)
            ) {
              commands.setNodeSelection(from + 1)
            }

            return success
          })
        },
    }
  },
})
