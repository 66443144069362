import { FormSelect, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import React from 'react'
import { SubstitutionData } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twJoin } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'

interface Props {
  substitutions: SubstitutionData[]
  className?: string
  editor: Editor | null
}

const SubstitutionSelect: React.FC<Props> = ({ substitutions, className, editor }) => {
  const { t } = useLocoTranslation()

  const onChange = (value: string | undefined) => {
    if (!editor) return null

    value && editor.chain().focus().insertContent(value).run()
  }

  const isDisabled = !editor?.options.editable

  return (
    <div className={className}>
      <Tooltip label={t('dashboard.email.substitute_variables')} anchor="left" {...TOOLTIP_STYLES}>
        <FormSelect
          disabled={isDisabled}
          arrowButtonClassName={twJoin(isDisabled && 'stroke-gray')}
          className={twJoin(
            'w-[210px]',
            isDisabled
              ? 'bg-gray/50 text-gray'
              : 'placeholder:text-darkblue focus:placeholder:text-gray-300/70',
          )}
          placeholder={t('dashboard.email.substitute_variables')}
          value={undefined}
          data={substitutions.map(({ label, data }) => ({ id: data, caption: label }))}
          onChange={onChange}
        />
      </Tooltip>
    </div>
  )
}

export default SubstitutionSelect
