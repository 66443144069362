import { ArrowDownIcon, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { FC } from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { DEFAULT_FONT_SIZE, TOOLTIP_STYLES } from '../../constans/default-styles'
import TextHeightIcon from '../../icons/text-height-icon'
import { getFontSizeValues } from '../../utils/font-size'
import { isImageSelected } from '../../utils/is-image-selected'
import { PluginControlContainer } from '../plugin-control-container'

interface Props {
  className?: string
  editor: Editor | null
}

const FontSizeSelect: FC<Props> = ({ className, editor }) => {
  const { t } = useLocoTranslation()

  const onChangeFontSize = (value: string | undefined) => {
    if (!editor || !value) return null

    editor.chain().focus().setFontSize(value).run()
  }

  const selectedFontSize: string | undefined = editor?.getAttributes('textStyle').fontSize

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  return (
    <div className={className}>
      <ListBoxSelect
        disabled={isDisabled}
        value={editor?.getAttributes('textStyle').fontSize}
        onChange={onChangeFontSize}
        data={getFontSizeValues()}
      >
        {open => (
          <Tooltip
            label={t('dashboard.simple_mail_editor.font_size')}
            anchor="left"
            {...TOOLTIP_STYLES}
          >
            <PluginControlContainer
              disabled={isDisabled}
              className={twMerge('w-[110px]', isDisabled && 'text-gray')}
            >
              <TextHeightIcon className={twMerge(isDisabled && 'fill-gray')} />
              {selectedFontSize ?? DEFAULT_FONT_SIZE}
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </PluginControlContainer>
          </Tooltip>
        )}
      </ListBoxSelect>
    </div>
  )
}

export default FontSizeSelect
