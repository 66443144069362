export enum EditorExtensionEnum {
  Document = 'Document',
  Paragraph = 'Paragraph',
  Text = 'Text',
  TextStyle = 'TextStyle',
  FontFamily = 'FontFamily',
  Heading = 'Heading',
  Bold = 'Bold',
  Italic = 'Italic',
  Strike = 'Strike',
  Underline = 'Underline',
  BulletList = 'BulletList',
  OrderedList = 'OrderedList',
  ListItem = 'ListItem',
  TextAlign = 'TextAlign',
  Link = 'Link',
  History = 'History',
  Color = 'Color',
  BackgroundColor = 'BackgroundColor',
  FontSize = 'FontSize',
  Indent = 'Indent',
  HardBreak = 'HardBreak',
  Substitution = 'Substitution',
  SpecialCharacters = 'SpecialCharacters',
  Image = 'Image',
  DropCursor = 'DropCursor',
}
