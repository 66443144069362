import { FormInput, NeutralButton, PrimaryButton, Tooltip } from '@systemeio/ui-shared'
import { BubbleMenu, Editor } from '@tiptap/react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twJoin } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../constans/default-styles'
import { ImageBubbleMenuState, ImageModeEnum, ImageNameEnum } from '../enums/image-enum'
import CaptionIcon from '../icons/caption-icon'
import HeadingIcon from '../icons/heading-icon'
import ImageCenteredIcon from '../icons/image-centered-icon'
import ImageInlineIcon from '../icons/image-inline-icon'
import ImageSideIcon from '../icons/image-side-icon'
import LinkIcon from '../icons/link-icon'
import LinkUnsetIcon from '../icons/link-unset-icon'
import PenIcon from '../icons/pen-icon'
import SaveIcon from '../icons/save-icon'
import TrashIcon from '../icons/trash-icon'
import { isImageSelected } from '../utils/is-image-selected'

const setImagePropsFromEditor = (editor: Editor | null) => {
  const state = editor?.state
  const from = state?.selection.from
  const node = state?.doc.nodeAt(from ?? 0)?.type.name

  const editorCaption: string | null = node ? editor?.getAttributes(node).caption : null
  const editorAlt: string | null = node ? editor?.getAttributes(node).alt : null
  const editorLink: string | null = node ? editor?.getAttributes(node).href : null

  return {
    [ImageBubbleMenuState.EditAltMode]: editorAlt ?? '',
    [ImageBubbleMenuState.EditLinkMode]: editorLink ?? '',
    [ImageBubbleMenuState.EditCaptionMode]: editorCaption ?? '',
  }
}

const ImageBubbleMenu: React.FC<{ editor: Editor | null }> = ({ editor }) => {
  const { t } = useLocoTranslation()

  const [imageProps, setImageProps] = useState(setImagePropsFromEditor(editor))
  const [bubbleMenuMode, setBubbleMenuState] = useState<ImageBubbleMenuState>(
    ImageBubbleMenuState.InitialMode,
  )

  const state = editor?.state
  const from = state?.selection.from
  const node = state?.doc.nodeAt(from ?? 0)?.type.name

  const imageMode: ImageModeEnum | null = node ? editor?.getAttributes(node).imageMode : null

  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    const clearImageBubbleMenu = () => {
      setBubbleMenuState(ImageBubbleMenuState.InitialMode)
      setImageProps(setImagePropsFromEditor(editor))
    }
    clearImageBubbleMenu()

    return () => clearImageBubbleMenu()
  }, [from, editor])

  if (!editor || !editor?.options.editable) return null

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    setImageProps(prev => ({ ...prev, [bubbleMenuMode]: e.target.value }))
  }

  const onSaveText = () => {
    if (
      bubbleMenuMode === ImageBubbleMenuState.InitialMode ||
      bubbleMenuMode === ImageBubbleMenuState.ViewLinkMode ||
      !node
    )
      return

    editor
      .chain()
      .focus()
      .updateAttributes(node, { [bubbleMenuMode]: imageProps[bubbleMenuMode] })
      .run()

    setBubbleMenuState(ImageBubbleMenuState.InitialMode)
  }

  const onRemoveText = (bubbleMenuMode: ImageBubbleMenuState) => {
    if (!node) return

    editor
      .chain()
      .focus()
      .updateAttributes(node, { [bubbleMenuMode]: null })
      .run()

    setImageProps(prev => ({ ...prev, [bubbleMenuMode]: '' }))

    setBubbleMenuState(ImageBubbleMenuState.InitialMode)
  }

  const onChangeImageMode = (newImageMode: ImageModeEnum) => {
    editor.chain().focus().toggleImageMode(newImageMode).run()
  }

  const onClickCaptionButton = () => {
    if (imageMode === ImageModeEnum.Inline) {
      onChangeImageMode(ImageModeEnum.BlockCenter)
    }

    setTimeout(() => {
      setBubbleMenuState(ImageBubbleMenuState.EditCaptionMode)
    }, 0)
  }

  const hasAlt = imageProps[ImageBubbleMenuState.EditAltMode].length > 0
  const hasCaption = imageProps[ImageBubbleMenuState.EditCaptionMode].length > 0
  const hasLink = imageProps[ImageBubbleMenuState.EditLinkMode].length > 0

  const renderBubbleMenuContent = () => {
    switch (bubbleMenuMode) {
      case ImageBubbleMenuState.ViewLinkMode: {
        return (
          <>
            <div className="w-[180px] truncate">
              <a
                href={imageProps[ImageBubbleMenuState.EditLinkMode]}
                target={'_blank'}
                className="text-blue underline px-3 w-fit overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {imageProps[ImageBubbleMenuState.EditLinkMode]}
              </a>
            </div>
            <PrimaryButton
              type="button"
              onClick={() => setBubbleMenuState(ImageBubbleMenuState.EditLinkMode)}
            >
              <PenIcon className="fill-white" />
            </PrimaryButton>
            <NeutralButton
              type="button"
              onClick={() => onRemoveText(ImageBubbleMenuState.EditLinkMode)}
            >
              <LinkUnsetIcon />
            </NeutralButton>
          </>
        )
      }
      case ImageBubbleMenuState.EditCaptionMode:
      case ImageBubbleMenuState.EditAltMode:
      case ImageBubbleMenuState.EditLinkMode: {
        const value = imageProps[bubbleMenuMode]
        let placeholder = ''

        switch (bubbleMenuMode) {
          case ImageBubbleMenuState.EditCaptionMode: {
            placeholder = t('dashboard.simple_mail_editor.caption')
            break
          }
          case ImageBubbleMenuState.EditAltMode:
            placeholder = t('dashboard.simple_mail_editor.alt_text')
            break
          case ImageBubbleMenuState.EditLinkMode:
            placeholder = t('dashboard.simple_mail_editor.link')
            break
        }

        return (
          <>
            <FormInput placeholder={placeholder} value={value} onChange={onChangeText} />
            <PrimaryButton type="button" disabled={!value.length} onClick={onSaveText}>
              <SaveIcon className="fill-white" />
            </PrimaryButton>
            {bubbleMenuMode !== ImageBubbleMenuState.EditLinkMode && (
              <NeutralButton
                type="button"
                disabled={!value.length}
                onClick={() => onRemoveText(bubbleMenuMode)}
              >
                <TrashIcon className={twJoin(!value.length && 'fill-gray')} />
              </NeutralButton>
            )}
          </>
        )
      }
      case ImageBubbleMenuState.InitialMode:
      default:
        return (
          <>
            <Tooltip
              label={t('dashboard.simple_mail_editor.image_in_line')}
              anchor="left"
              disabled={isHidden}
              {...TOOLTIP_STYLES}
            >
              <NeutralButton
                type="button"
                onClick={() => onChangeImageMode(ImageModeEnum.Inline)}
                className={twJoin(imageMode === ImageModeEnum.Inline && 'border-blue')}
              >
                <ImageInlineIcon
                  className={twJoin(imageMode === ImageModeEnum.Inline && 'fill-blue')}
                />
              </NeutralButton>
            </Tooltip>
            <Tooltip
              label={t('dashboard.simple_mail_editor.image_center')}
              anchor="left"
              disabled={isHidden}
              {...TOOLTIP_STYLES}
            >
              <NeutralButton
                type="button"
                onClick={() => onChangeImageMode(ImageModeEnum.BlockCenter)}
                className={twJoin(imageMode === ImageModeEnum.BlockCenter && 'border-blue')}
              >
                <ImageCenteredIcon
                  className={twJoin(imageMode === ImageModeEnum.BlockCenter && 'fill-blue')}
                />
              </NeutralButton>
            </Tooltip>
            <Tooltip
              label={t('dashboard.simple_mail_editor.image_side')}
              anchor="left"
              disabled={isHidden}
              {...TOOLTIP_STYLES}
            >
              <NeutralButton
                type="button"
                onClick={() => onChangeImageMode(ImageModeEnum.BlockRight)}
                className={twJoin(imageMode === ImageModeEnum.BlockRight && 'border-blue')}
              >
                <ImageSideIcon
                  className={twJoin(imageMode === ImageModeEnum.BlockRight && 'fill-blue')}
                />
              </NeutralButton>
            </Tooltip>
            <Tooltip
              disabled={isHidden}
              label={t('dashboard.simple_mail_editor.image_change_alt_text')}
              anchor="left"
              {...TOOLTIP_STYLES}
            >
              <NeutralButton
                type="button"
                className={twJoin(hasAlt && 'border-blue')}
                onClick={() => setBubbleMenuState(ImageBubbleMenuState.EditAltMode)}
              >
                <HeadingIcon className={twJoin(hasAlt && 'fill-blue')} />
              </NeutralButton>
            </Tooltip>
            <Tooltip
              label={t('dashboard.simple_mail_editor.image_change_caption')}
              anchor="left"
              {...TOOLTIP_STYLES}
            >
              <NeutralButton
                type="button"
                className={twJoin(hasCaption && 'border-blue')}
                onClick={onClickCaptionButton}
              >
                <CaptionIcon className={twJoin(hasCaption && 'fill-blue')} />
              </NeutralButton>
            </Tooltip>
            <Tooltip
              disabled={isHidden}
              label={t('dashboard.simple_mail_editor.image_change_link')}
              anchor="left"
              {...TOOLTIP_STYLES}
            >
              <NeutralButton
                type="button"
                className={twJoin(hasLink && 'border-blue')}
                onClick={() =>
                  setBubbleMenuState(
                    !hasLink
                      ? ImageBubbleMenuState.EditLinkMode
                      : ImageBubbleMenuState.ViewLinkMode,
                  )
                }
              >
                <LinkIcon className={twJoin(hasLink && 'fill-blue')} />
              </NeutralButton>
            </Tooltip>
          </>
        )
    }
  }

  return (
    <BubbleMenu
      className="flex items-center gap-2 p-2 bg-white rounded-lg shadow-lg border border-gray-200"
      editor={editor}
      updateDelay={0}
      tippyOptions={{
        duration: 200,
        placement: 'bottom',
        appendTo: 'parent',
        maxWidth: 400,
        onHide() {
          setBubbleMenuState(ImageBubbleMenuState.InitialMode)
          setIsHidden(true)
        },
        onShow() {
          setIsHidden(false)
        },
      }}
      shouldShow={({ editor }) => {
        if (!isImageSelected(editor)) return false

        return (
          editor?.getAttributes(ImageNameEnum.Block).showBubbleMenu ||
          editor?.getAttributes(ImageNameEnum.Inline).showBubbleMenu
        )
      }}
    >
      {renderBubbleMenuContent()}
    </BubbleMenu>
  )
}

export default ImageBubbleMenu
