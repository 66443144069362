import { NeutralButton, PrimaryButton, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import React, { FC, useEffect, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { EditorExtensionEnum } from '../../enums/editor-extensions-enum'
import { TextFormatEnum } from '../../enums/text-format-enum'
import { useShortcuts } from '../../hooks/use-shortcuts'
import { getExistingExtensions } from '../../utils/get-extensions'
import { isImageSelected } from '../../utils/is-image-selected'
import { getTextFormatIcon, setTextFormat } from '../../utils/text-format'

interface Props {
  extensions: EditorExtensionEnum[]
  className?: string
  shouldHideTooltip?: boolean
  editor: Editor | null
}

const TextFormatButtons: FC<Props> = ({ extensions, className, shouldHideTooltip, editor }) => {
  const { getShortcut, rawShortcuts } = useShortcuts()
  const { t } = useLocoTranslation()

  const [formatButtons, setFormatButtons] = useState<TextFormatEnum[]>([])

  useEffect(() => {
    const buttons = [
      extensions.includes(EditorExtensionEnum.Bold) ? TextFormatEnum.Bold : null,
      extensions.includes(EditorExtensionEnum.Italic) ? TextFormatEnum.Italic : null,
      extensions.includes(EditorExtensionEnum.Underline) ? TextFormatEnum.Underline : null,
      extensions.includes(EditorExtensionEnum.Strike) ? TextFormatEnum.Strike : null,
    ].filter(Boolean) as TextFormatEnum[]

    setFormatButtons(buttons)
  }, [extensions])

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  const renderButton = (formatType: TextFormatEnum) => {
    const isActive = editor?.isActive(formatType)

    const onClickFormatButton = () => setTextFormat({ formatType, editor })

    const Button = isActive ? PrimaryButton : NeutralButton
    const Icon = getTextFormatIcon({ formatType })

    return (
      <Tooltip
        key={formatType}
        disabled={shouldHideTooltip}
        label={`${t(`dashboard.simple_mail_editor.${formatType}`)} (${getShortcut(
          rawShortcuts[formatType].toggle,
        )})`}
        {...TOOLTIP_STYLES}
      >
        <Button
          type="button"
          disabled={isDisabled}
          className="w-[48px]"
          key={formatType}
          onClick={onClickFormatButton}
        >
          <Icon className={twMerge(isActive && 'fill-white', isDisabled && 'fill-gray')} />
        </Button>
      </Tooltip>
    )
  }

  const { hasBold, hasItalic, hasUnderline, hasStrike } = getExistingExtensions(extensions)

  if (!hasBold && !hasItalic && !hasUnderline && !hasStrike) return null

  return (
    <div className={twMerge('flex gap-1', className)}>
      {formatButtons.map(format => renderButton(format))}
    </div>
  )
}

export default TextFormatButtons
