import { Editor } from '@tiptap/react'
import { useEffect, useState } from 'react'

export const useGetParentCount = ({
  editor,
  getPos,
  nodeName,
}: {
  editor: Editor
  getPos: () => number
  nodeName?: string
}) => {
  const [realDepth, setRealDepth] = useState(0)

  useEffect(() => {
    const calculateRealDepth = () => {
      let depth = 0
      const $pos = editor.state.doc.resolve(getPos())

      for (let d = $pos.depth; d >= 0; d--) {
        const node = $pos.node(d)
        if (nodeName) {
          if (node?.type.name === nodeName) {
            depth++
          }
        } else {
          depth++
        }
      }

      return depth
    }

    const depth = calculateRealDepth()

    if (realDepth !== depth) {
      setRealDepth(depth)
    }
  }, [editor.state.doc, getPos, nodeName, realDepth])

  return { realDepth }
}
