import { NeutralButton, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { IndentProps } from '../../extentions/indent'
import IndentIcon from '../../icons/indent-icon'
import OutdentIcon from '../../icons/outdent-icon'
import { isImageSelected } from '../../utils/is-image-selected'

interface Props {
  editor: Editor | null
  className?: string
}

const IndentButtons: FC<Props> = ({ className, editor }) => {
  const { t } = useLocoTranslation()

  const getCurrentIndent = () => {
    if (!editor) return 0

    const nodeTypes = ['paragraph', 'heading', 'bulletList', 'orderedList']

    for (const type of nodeTypes) {
      const attrs = editor.getAttributes(type)
      if (attrs && typeof attrs.indent === 'number') {
        return attrs.indent
      }
    }

    return 0
  }

  const currentIndent = getCurrentIndent()

  const isMinDisabled =
    !editor?.options.editable || isImageSelected(editor) || currentIndent <= IndentProps.min
  const isMaxDisabled =
    !editor?.options.editable || isImageSelected(editor) || currentIndent >= IndentProps.max

  const onClickOutdent = () => editor?.commands.outdent()

  const onClickIndent = () => editor?.commands.indent()

  return (
    <div className={twMerge('flex gap-1', className)}>
      <Tooltip label={t('dashboard.simple_mail_editor.decrease_intent')} {...TOOLTIP_STYLES}>
        <NeutralButton type="button" onClick={onClickOutdent} disabled={isMinDisabled}>
          <OutdentIcon className={isMinDisabled ? 'fill-gray' : 'fill-black'} />
        </NeutralButton>
      </Tooltip>
      <Tooltip label={t('dashboard.simple_mail_editor.increase_intent')} {...TOOLTIP_STYLES}>
        <NeutralButton type="button" onClick={onClickIndent} disabled={isMaxDisabled}>
          <IndentIcon className={isMaxDisabled ? 'fill-gray' : 'fill-black'} />
        </NeutralButton>
      </Tooltip>
    </div>
  )
}

export default IndentButtons
