import { NeutralButton } from '@systemeio/ui-shared'
import { NodeViewProps, useReactNodeView } from '@tiptap/react'
import { Resizable, ResizableProps } from 're-resizable'
import React, { FC, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  FIGCAPTION_STYLES,
  FIGURE_CENTERED_STYLES,
  FIGURE_RIGHT_STYLES,
} from '../constans/default-styles'
import { ImageModeEnum, InsertParagraphEnum } from '../enums/image-enum'
import LinkIcon from '../icons/link-icon'
import TurnArrowIcon from '../icons/turn-arrow-icon'

const CustomImage: FC<NodeViewProps> = ({ node, selected, editor, getPos, updateAttributes }) => {
  const {
    src,
    alt,
    title,
    caption,
    width,
    height,
    containerWidth,
    href,
    imageMode,
    uploadingProps,
  } = node.attrs

  const [isResizing, setIsResizing] = useState(false)

  const [hover, setHover] = useState(false)

  const isEditable = editor?.options.editable && !uploadingProps

  const isInlineMode = imageMode === ImageModeEnum.Inline
  const isBlockCenterMode = imageMode === ImageModeEnum.BlockCenter
  const isBlockRightMode = imageMode === ImageModeEnum.BlockRight

  const handleImageClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (typeof getPos === 'function') {
      const pos = getPos()
      editor.commands.setNodeSelection(pos)
      editor.commands.focus()
    }
  }

  const onChangeImageSize: ResizableProps['onResize'] = (_, __, element) => {
    const wrapperWidth = element.style.width

    const parentWidth = element.parentElement?.offsetWidth
    if (!parentWidth) return

    const image = element.querySelector('img')
    if (!image) return

    let widthInPercent
    if (wrapperWidth.includes('%')) {
      widthInPercent = wrapperWidth
    } else if (wrapperWidth.includes('px')) {
      const wrapperWidthNumber = wrapperWidth.split('px')[0]

      widthInPercent = (Number(wrapperWidthNumber) / parentWidth) * 100 + '%'
    } else {
      widthInPercent = (Number(image.naturalWidth) / parentWidth) * 100 + '%'
    }

    updateAttributes({
      containerWidth: widthInPercent,
      width: image.naturalWidth,
      height: image.naturalHeight,
    })
  }

  const onSetParagraph = (insertPosition: InsertParagraphEnum) => {
    if (!editor) return

    const selectionPos = getPos()

    if (selectionPos === null) return

    let position = 0

    switch (insertPosition) {
      case InsertParagraphEnum.Before:
        position = selectionPos > 0 ? selectionPos - 1 : 0
        break
      case InsertParagraphEnum.After:
        position = selectionPos + node.nodeSize
    }

    editor
      .chain()
      .insertContentAt(position, {
        type: 'paragraph',
        content: [{ type: 'text', text: ' ' }],
      })
      .focus()
      .run()
  }

  const { onDragStart } = useReactNodeView()

  const renderNodeViewWrapper = (children: React.JSX.Element): React.JSX.Element => {
    const borderClass = twMerge(
      selected || isResizing ? 'bg-blue' : hover ? 'bg-yellow' : 'bg-transparent',
      'main-transition-colors',
    )

    const imageBorder = 2
    return (
      <Resizable
        data-node-view-wrapper=""
        // @ts-ignore
        onDragStart={onDragStart}
        style={{
          ...(isBlockCenterMode && FIGURE_CENTERED_STYLES),
          ...(isBlockRightMode && FIGURE_RIGHT_STYLES),
        }}
        as={isInlineMode ? 'span' : 'figure'}
        size={{ width: containerWidth, height: '100%' }}
        handleClasses={{
          top: borderClass,
          bottom: borderClass,
          left: borderClass,
          right: borderClass,
        }}
        handleStyles={{
          top: {
            height: imageBorder,
            top: -imageBorder,
            width: `calc(100% + ${imageBorder}px)`,
            left: -imageBorder,
          },
          bottom: {
            height: imageBorder,
            bottom: -imageBorder,
            width: `calc(100% + ${imageBorder}px)`,
            right: -imageBorder,
          },
          left: {
            width: imageBorder,
            left: -imageBorder,
            height: `calc(100% + ${imageBorder}px)`,
            bottom: -imageBorder,
          },
          right: {
            width: imageBorder,
            right: -imageBorder,
            height: `calc(100% + ${imageBorder}px)`,
            top: -imageBorder,
          },
        }}
        enable={isEditable ? undefined : false}
        onResize={onChangeImageSize}
        minWidth={50}
        minHeight={50}
        maxWidth={'100%'}
        lockAspectRatio={true}
        onResizeStart={() => {
          setIsResizing(true)
          updateAttributes({
            showBubbleMenu: false,
          })
        }}
        onResizeStop={() => {
          setIsResizing(false)
        }}
        className={twMerge(
          isInlineMode ? 'inline-flex' : 'flex',
          'flex-col',
          href ? 'cursor-pointer' : 'cursor-default',
          isBlockRightMode && 'justify-end',
          isBlockCenterMode && 'justify-center',
        )}
      >
        {children}
        {caption && <figcaption style={FIGCAPTION_STYLES}>{caption}</figcaption>}
      </Resizable>
    )
  }

  const renderImage = (
    props?: React.ImgHTMLAttributes<HTMLImageElement> & { 'data-drag-handle'?: string },
  ) => (
    <img
      src={src}
      alt={alt ?? ''}
      title={title ?? ''}
      width={width}
      height={height}
      style={{
        minWidth: '100%',
        maxWidth: '100%',
        aspectRatio: width && height ? `${width}/${height}` : undefined,
      }}
      {...props}
    />
  )

  if (uploadingProps?.isUploading) {
    return renderNodeViewWrapper(renderImage({ className: 'animate-pulse' }))
  }

  const renderImageContent = () => {
    const widthInPercent = containerWidth.includes('%')
      ? Number(containerWidth.split('%')[0]).toFixed(2)
      : undefined
    return (
      <>
        {renderImage({
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false),
          onClick: handleImageClick,
          onMouseDownCapture: () =>
            updateAttributes({
              showBubbleMenu: true,
            }),
          'data-drag-handle': '',
        })}
        {isEditable && selected && !isInlineMode && (
          <>
            <NeutralButton
              type="button"
              className={twMerge(
                'group absolute left-[5%] -top-2.5 bg-gray hover:bg-blue p-1 min-h-[24px] z-1',
                selected && 'bg-blue',
              )}
              onClick={() => onSetParagraph(InsertParagraphEnum.Before)}
            >
              <TurnArrowIcon
                className={twMerge(
                  'rotate-90 fill-gray-300 group-hover:fill-white',
                  selected && 'fill-white',
                )}
              />
            </NeutralButton>
            <NeutralButton
              type="button"
              className={twMerge(
                'group absolute right-[5%] -bottom-2.5 bg-gray hover:bg-blue p-1 min-h-[24px] z-1',
                selected && 'bg-blue',
              )}
              onClick={() => onSetParagraph(InsertParagraphEnum.After)}
            >
              <TurnArrowIcon
                className={twMerge(
                  'rotate-90 fill-gray-300 group-hover:fill-white',
                  selected && 'fill-white',
                )}
              />
            </NeutralButton>
          </>
        )}
        {href && (
          <div
            className={
              'flex items-center justify-center absolute right-3 top-3 h-[20px] w-[20px] bg-black/30 rounded'
            }
          >
            <LinkIcon className={'fill-white'} />
          </div>
        )}
        {isResizing && widthInPercent && (
          <span className={'absolute left-3 bottom-3 text-white bg-black/70 px-1 text-[10px]'}>
            {widthInPercent}%
          </span>
        )}
      </>
    )
  }

  const renderLinkWrapper = (children: React.JSX.Element): React.JSX.Element => {
    if (!href) {
      return children
    }

    return (
      <a className={'flex w-full h-full'} href={href} onClick={e => e.preventDefault()}>
        {children}
      </a>
    )
  }

  return renderNodeViewWrapper(renderLinkWrapper(renderImageContent()))
}

export default CustomImage
