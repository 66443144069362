import { ArrowDownIcon, Popover, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { useState } from 'react'
import { specialCharactersData } from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/constans/special-characters-data'
import { SelectedCategoriesEnum } from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/enums/selected-categories-enum'
import SpecialCharactersIcon from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/icons/special-characters'
import { getCharactersCategoriesData } from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/utils/get-characters-categories-data'
import { FormSelectInlineBordered } from 'shared/components/form-select/form-select-inline-bordered'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twJoin, twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { PluginControlContainer } from '../plugin-control-container'

interface Props {
  className?: string
  editor: Editor | null
}

const SpecialCharacters = ({ className, editor }: Props) => {
  const { t } = useLocoTranslation()

  const [selected, setSelected] = useState<SelectedCategoriesEnum>(SelectedCategoriesEnum.All)
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null)

  const setSymbol = (symbol: string) => {
    if (!editor) return null

    editor.chain().focus().insertContent(symbol).run()
  }

  const isDisabled = !editor?.options.editable

  return (
    <Popover
      className={twMerge('group', className)}
      popoverClassName={
        'bg-white rounded-lg shadow-around-sm z-20 transition duration-200 ease-out'
      }
      disabled={isDisabled}
      anchor="bottom"
      label={
        <div className={'max-w-[280px] lg:max-w-[320px]'}>
          <div className={'flex items-center justify-between pb-2 border-b border-gray-200'}>
            <span className={'text-base text-darkblue'}>
              {t('dashboard.email.editor.special_characters.title')}
            </span>
            <FormSelectInlineBordered
              onChange={value => value && setSelected(value)}
              value={selected}
              data={getCharactersCategoriesData(t)}
              withoutCloseIcon
              arrowButtonClassName={'h-[30px] right-1.5'}
              className={'pl-1.5 py-1 pr-6'}
            />
          </div>
          <div className="flex flex-wrap gap-2 overflow-auto max-h-[180px] customScroll py-3">
            {specialCharactersData[selected].map((item, index) => (
              <div
                key={item.code}
                className={
                  'w-[26px] h-[26px] cursor-pointer text-center rounded hover:border-blue main-transition-colors'
                }
                onClick={() => setSymbol(item.symbol)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <span className={'text-base text-darkblue'}>{item.symbol}</span>
              </div>
            ))}
          </div>
          <div className={'h-5 flex items-center justify-between mt-3'}>
            {hoveredIndex !== null && (
              <>
                <span className={'text-xs'}>
                  {t(specialCharactersData[selected][hoveredIndex].name)}
                </span>{' '}
                <span className={'text-xs'}>
                  {specialCharactersData[selected][hoveredIndex].code}
                </span>
              </>
            )}
          </div>
        </div>
      }
    >
      <Tooltip
        label={t('dashboard.email.editor.special_characters.title')}
        anchor="left"
        {...TOOLTIP_STYLES}
      >
        <PluginControlContainer disabled={isDisabled}>
          <SpecialCharactersIcon className={twJoin(isDisabled && 'fill-gray')} />
          <ArrowDownIcon
            className={twJoin(
              'transition-transform ease-linear duration-200 group-data-[open]:rotate-180',
              isDisabled && 'stroke-gray',
            )}
          />
        </PluginControlContainer>
      </Tooltip>
    </Popover>
  )
}

export default SpecialCharacters
