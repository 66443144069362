import { ArrowDownIcon, Tooltip } from '@systemeio/ui-shared'
import { Level } from '@tiptap/extension-heading'
import { Editor } from '@tiptap/react'
import React from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { TextLevelEnum } from '../../enums/text-level-enum'
import HeadingIcon from '../../icons/heading-icon'
import { isImageSelected } from '../../utils/is-image-selected'
import { getCurrentTextLevelValue, getTextLevelValues } from '../../utils/text-level'
import { PluginControlContainer } from '../plugin-control-container'

interface Props {
  className?: string
  editor: Editor | null
}

const TextLevelSelect: React.FC<Props> = ({ className, editor }) => {
  const { t } = useLocoTranslation()

  const onChangeTextLevel = (value: TextLevelEnum | undefined) => {
    if (!editor) return null

    if (value === TextLevelEnum.Default) {
      return editor.chain().focus().setParagraph().run()
    }

    editor
      .chain()
      .focus()
      .toggleHeading({ level: value as Level })
      .run()
  }

  const currentCaption = getTextLevelValues(t).find(
    i => i.id === getCurrentTextLevelValue(editor),
  )?.caption

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  return (
    <div className={className}>
      <ListBoxSelect
        disabled={isDisabled}
        value={getCurrentTextLevelValue(editor)}
        onChange={onChangeTextLevel}
        data={getTextLevelValues(t)}
      >
        {open => (
          <Tooltip
            label={t('dashboard.simple_mail_editor.text_level')}
            anchor="left"
            {...TOOLTIP_STYLES}
          >
            <PluginControlContainer
              disabled={isDisabled}
              className={twMerge('w-[155px]', isDisabled && 'text-gray')}
            >
              <HeadingIcon className={twMerge(isDisabled && 'fill-gray')} />
              {currentCaption}
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </PluginControlContainer>
          </Tooltip>
        )}
      </ListBoxSelect>
    </div>
  )
}

export default TextLevelSelect
