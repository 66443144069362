import { FormInput, NeutralButton, PrimaryButton, SaveIcon } from '@systemeio/ui-shared'
import { BubbleMenu, Editor } from '@tiptap/react'
import React, { useEffect, useRef, useState } from 'react'
import CancelIcon from '../icons/cancel-icon'
import LinkUnsetIcon from '../icons/link-unset-icon'
import PenIcon from '../icons/pen-icon'
import { isCursorInsideLink } from '../utils/link'

const LinkBubbleMenu: React.FC<{ editor: Editor | null }> = ({ editor }) => {
  const href = editor?.getAttributes('link').href
  const editorLink = useRef<string | undefined>(href)
  const [newLink, setNewLink] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    editorLink.current = href
    setNewLink(editorLink.current || '')
    setIsEditing(!editorLink.current)
  }, [href])

  const unsetLink = () =>
    editor?.chain().focus().extendMarkRange('link').unsetLink().setMeta('addToHistory', false).run()

  const onChangeLink = (value: string) => {
    setIsEditing(true)

    setNewLink(value)
  }

  const onSaveLink = () => {
    if (!editor) return

    setIsEditing(false)

    editor.chain().focus().extendMarkRange('link').setLink({ href: newLink }).run()
  }

  const onCancelLinkChange = () => {
    if (!editorLink.current) {
      unsetLink()
    }
    setNewLink(prevState => editorLink.current || prevState)
    setIsEditing(false)
  }

  const onHideMenu = () => {
    if (!editorLink.current) {
      unsetLink()
      setNewLink('')
    } else {
      setNewLink(prevState => editorLink.current || prevState)
      setIsEditing(false)
    }
  }

  if (!editor || !editor?.options.editable) return null

  return (
    <BubbleMenu
      className="flex items-center gap-2 p-2 bg-white rounded-lg shadow-lg border border-gray-200"
      editor={editor}
      shouldShow={({ editor }) => {
        return isCursorInsideLink(editor)
      }}
      updateDelay={0}
      tippyOptions={{
        appendTo: 'parent',
        duration: [200, 0],
        placement: 'bottom',
        offset: [0, 10],
        hideOnClick: true,
        onHide: onHideMenu,
      }}
    >
      <div className="flex gap-1 items-center">
        {isEditing ? (
          <>
            <FormInput
              value={newLink}
              placeholder={'Link URL'}
              onChange={e => onChangeLink(e.target.value)}
            />
            <PrimaryButton type="button" disabled={newLink === ''} onClick={onSaveLink}>
              <SaveIcon />
            </PrimaryButton>
            <NeutralButton type="button" onClick={onCancelLinkChange}>
              <CancelIcon />
            </NeutralButton>
          </>
        ) : (
          <>
            <div className="w-[180px] truncate">
              <a href={newLink} target={'_blank'} className="text-blue underline px-3 truncate">
                {newLink}
              </a>
            </div>
            <PrimaryButton type="button" onClick={() => setIsEditing(true)}>
              <PenIcon className="fill-white" />
            </PrimaryButton>
            <NeutralButton type="button" onClick={unsetLink}>
              <LinkUnsetIcon />
            </NeutralButton>
          </>
        )}
      </div>
    </BubbleMenu>
  )
}

export default LinkBubbleMenu
