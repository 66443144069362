import { ArrowDownIcon, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { FC } from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { FontFamilyEnum } from '../../enums/font-family-enum'
import FontIcon from '../../icons/font-icon'
import { getFontFamilyValues } from '../../utils/font-family'
import { isImageSelected } from '../../utils/is-image-selected'
import { PluginControlContainer } from '../plugin-control-container'

interface Props {
  className?: string
  editor: Editor | null
}

const FontFamilySelect: FC<Props> = ({ className, editor }) => {
  const { t } = useLocoTranslation()

  const onChangeFontFamily = (value: string | undefined) => {
    if (!editor || !value) return null

    if (value === FontFamilyEnum.Default) {
      editor.chain().focus().unsetFontFamily().run()
    } else {
      editor.chain().focus().setFontFamily(value).run()
    }
  }

  const selectedFont: string | undefined = editor
    ?.getAttributes('textStyle')
    .fontFamily?.replace(/"/g, '')

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  return (
    <div className={className}>
      <ListBoxSelect
        disabled={isDisabled}
        data={getFontFamilyValues(t)}
        value={editor?.getAttributes('textStyle').fontFamily?.replace(/"/g, '')}
        onChange={onChangeFontFamily}
      >
        {open => (
          <Tooltip
            label={t('dashboard.simple_mail_editor.font_family')}
            anchor="left"
            {...TOOLTIP_STYLES}
          >
            <PluginControlContainer
              disabled={isDisabled}
              className={twMerge('w-[130px]', isDisabled && 'text-gray')}
            >
              <FontIcon className={twMerge(isDisabled && 'fill-gray', 'shrink-0')} />
              <span className="truncate">
                {selectedFont
                  ? selectedFont.split(',')[0]
                  : t('dashboard.email.editor.font_family.default')}
              </span>
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </PluginControlContainer>
          </Tooltip>
        )}
      </ListBoxSelect>
    </div>
  )
}

export default FontFamilySelect
