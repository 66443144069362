export enum ImageNameEnum {
  Inline = 'inline-image',
  Block = 'block-image',
}

export enum ImageModeEnum {
  Inline = 'inline',
  BlockCenter = 'blockCenter',
  BlockRight = 'blockRight',
}

export enum InsertParagraphEnum {
  Before = 'before',
  After = 'after',
}

export enum ImageBubbleMenuState {
  InitialMode,
  ViewLinkMode,
  EditCaptionMode = 'caption',
  EditAltMode = 'alt',
  EditLinkMode = 'href',
}
