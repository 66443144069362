import { Editor } from '@tiptap/react'
import React, { FC } from 'react'
import SpecialCharacters from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/components/content-controls/special-characters'
import { SubstitutionData } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import { EditorExtensionEnum } from '../enums/editor-extensions-enum'
import { getExistingExtensions } from '../utils/get-extensions'
import BackgroundColorButton from './content-controls/background-color-button'
import FontFamilySelect from './content-controls/font-family-select'
import FontSizeSelect from './content-controls/font-size-select'
import HistoryButtons from './content-controls/history-buttons'
import ImageButton from './content-controls/image-button'
import IndentButtons from './content-controls/indent-buttons'
import LinkButton from './content-controls/link-button'
import ListButtons from './content-controls/list-buttons'
import SubstitutionSelect from './content-controls/substitution-select'
import TextAlignSelect from './content-controls/text-align-select'
import TextColorButton from './content-controls/text-color-button'
import TextFormatButtons from './content-controls/text-format-buttons'
import TextLevelSelect from './content-controls/text-level-select'

interface Props {
  substitutions?: SubstitutionData[]
  extensions: EditorExtensionEnum[]
  editor: Editor | null
}

const Toolbar: FC<Props> = ({ substitutions, extensions, editor }) => {
  const {
    hasHistory,
    hasHeading,
    hasIndent,
    hasTextAlign,
    hasLink,
    hasColor,
    hasFontSize,
    hasBackgroundColor,
    hasFontFamily,
    hasSubstitution,
    hasSpecialCharacters,
    hasImage,
  } = getExistingExtensions(extensions)

  return (
    <div className="sticky top-0 flex overflow-x-scroll sliderScroll customScroll bg-blue-100 divide-x divide-gray/30 border border-b-0 border-gray/30 rounded-t-lg p-1 z-10">
      {hasHistory && <HistoryButtons className="px-1" editor={editor} />}
      {hasHeading && <TextLevelSelect className="px-1" editor={editor} />}
      {(hasIndent || hasTextAlign) && (
        <div className="flex px-1">
          {hasIndent && <IndentButtons editor={editor} />}
          {hasTextAlign && <TextAlignSelect className="pl-1" editor={editor} />}
        </div>
      )}
      {hasSubstitution && substitutions && (
        <SubstitutionSelect substitutions={substitutions} className="px-1" editor={editor} />
      )}
      <TextFormatButtons extensions={extensions} className="px-1" editor={editor} />
      {(hasLink || hasImage) && (
        <div className={'flex px-1 gap-1'}>
          {hasLink && <LinkButton className="px-1 flex-shrink-0 w-[42px]" editor={editor} />}
          {hasImage && <ImageButton editor={editor} />}
        </div>
      )}
      <ListButtons editor={editor} extensions={extensions} className="px-1" />
      {(hasColor || hasFontSize || hasBackgroundColor || hasFontFamily) && (
        <div className="flex gap-1 items-center px-1">
          {hasColor && <TextColorButton editor={editor} />}
          {hasFontSize && <FontSizeSelect editor={editor} />}
          {hasBackgroundColor && <BackgroundColorButton editor={editor} />}
          {hasFontFamily && <FontFamilySelect editor={editor} />}
        </div>
      )}
      {hasSpecialCharacters && <SpecialCharacters className="px-1" editor={editor} />}
    </div>
  )
}

export default Toolbar
