import { DOMOutputSpec } from '@tiptap/pm/model'

export const getImageWithLinkHtml = (
  imageAttrs: Record<string, any>,
  linkAttrs?: { href?: string },
): DOMOutputSpec => {
  if (typeof linkAttrs?.href === 'string') {
    return ['a', linkAttrs, ['img', imageAttrs]]
  }

  return ['img', imageAttrs]
}
