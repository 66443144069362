import { ArrowDownIcon, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import React from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { TextAlignEnum } from '../../enums/text-align-enum'
import AlignCenterIcon from '../../icons/align-center-icon'
import AlignJustifyIcon from '../../icons/align-justify-icon'
import AlignLeftIcon from '../../icons/align-left-icon'
import AlignRightIcon from '../../icons/align-right-icon'
import { isImageSelected } from '../../utils/is-image-selected'
import { getCurrentTextAlignValue, getTextAlignValues } from '../../utils/text-align'
import { PluginControlContainer } from '../plugin-control-container'

interface Props {
  className?: string
  editor: Editor | null
}

const TextAlignSelect: React.FC<Props> = ({ className, editor }) => {
  const { t } = useLocoTranslation()

  const onChangeTextAlign = (value: TextAlignEnum | undefined) => {
    if (!editor || !value) return null

    editor.chain().focus().setTextAlign(value).run()
  }

  const selectedValue = getCurrentTextAlignValue(editor)

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  const renderValueIcon = () => {
    const className = twMerge(isDisabled && 'fill-gray')

    switch (selectedValue) {
      case TextAlignEnum.Right:
        return <AlignRightIcon className={className} />
      case TextAlignEnum.Center:
        return <AlignCenterIcon className={className} />
      case TextAlignEnum.Justify:
        return <AlignJustifyIcon className={className} />
      case TextAlignEnum.Left:
      default:
        return <AlignLeftIcon className={className} />
    }
  }

  return (
    <div className={className}>
      <ListBoxSelect
        disabled={isDisabled}
        value={getCurrentTextAlignValue(editor)}
        data={getTextAlignValues(selectedValue)}
        onChange={onChangeTextAlign}
        optionClassName={'hover:fill-white'}
      >
        {open => (
          <Tooltip
            label={t('dashboard.simple_mail_editor.text_align')}
            anchor="left"
            {...TOOLTIP_STYLES}
          >
            <PluginControlContainer disabled={isDisabled} className="w-[80px]">
              {renderValueIcon()}
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </PluginControlContainer>
          </Tooltip>
        )}
      </ListBoxSelect>
    </div>
  )
}

export default TextAlignSelect
