import {
  FileSourceEnum,
  FileTypeEnum,
  isAcceptTypeValid,
  useFileUploaderManager,
} from '@systemeio/file-manager'
import { toast } from '@systemeio/ui-shared'
import { EditorProps, EditorView } from '@tiptap/pm/view'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { ImageModeEnum, ImageNameEnum } from '../enums/image-enum'

const removeUploadingImage = (view: EditorView, id: string) => {
  view.state.doc.descendants((node, pos) => {
    if (node.type.name === ImageNameEnum.Block && node.attrs.uploadingProps?.id === id) {
      view.dispatch(view.state.tr.delete(pos, pos + node.nodeSize).setMeta('addToHistory', false))
      return false
    }
    return true
  })
}

export const useImageDropAndUpload = () => {
  const { t } = useLocoTranslation()

  // TODO create a new source for tip-tap
  const { startUploading } = useFileUploaderManager({
    source: FileSourceEnum.Ckeditor,
  })

  const onUpload = ({
    file,
    view,
    clientX,
    clientY,
  }: {
    file: File
    view: EditorView
    clientX: number
    clientY: number
  }) => {
    const id = crypto.randomUUID()

    startUploading(file)
      .then(file => {
        if (!file) {
          toast.error(t('global.error'))
          return removeUploadingImage(view, id)
        }

        view.state.doc.descendants((node, pos) => {
          if (node.type.name === ImageNameEnum.Block && node.attrs.uploadingProps?.id === id) {
            const newAttrs = {
              ...node.attrs,
              src: file.path,
              uploadingProps: null,
            }
            view.dispatch(
              view.state.tr.setNodeMarkup(pos, null, newAttrs).setMeta('addToHistory', false),
            )
            return false
          }
          return true
        })
      })
      .catch(() => {
        toast.error(t('global.error'))

        removeUploadingImage(view, id)
      })

    const { schema } = view.state
    const coordinates = view.posAtCoords({ left: clientX, top: clientY })

    const src = URL.createObjectURL(file)
    const img = new Image()
    img.src = src
    img.onload = () => {
      const node = schema.nodes[ImageNameEnum.Block].create({
        src,
        width: img.naturalWidth,
        height: img.naturalHeight,
        containerWidth: 'auto',
        imageMode: ImageModeEnum.BlockCenter,
        uploadingProps: {
          isUploading: true,
          id,
        },
      })

      const transaction = view.state.tr.insert(coordinates?.pos ?? 1, node)
      view.dispatch(transaction)
    }
  }

  const onPaste: EditorProps['handlePaste'] = (view, event) => {
    if (!event.clipboardData) return
    event.preventDefault()
    const { files } = event.clipboardData
    if (!files || !files[0]) return

    const file = files[0]

    // prevent further tip-tap paste event
    if (!isAcceptTypeValid(file.type, [FileTypeEnum.Image, FileTypeEnum.ImageOther])) return true

    // Get the current cursor position from the editor
    const { selection } = view.state
    const { from } = selection

    // Convert ProseMirror position to DOM coordinates
    const coords = view.coordsAtPos(from)

    onUpload({
      file,
      view,
      clientX: coords.left,
      clientY: coords.top,
    })

    // prevent further tip-tap paste event
    return true
  }

  const onDrop: EditorProps['handleDrop'] = (view, event) => {
    if (!event.dataTransfer) return
    event.preventDefault()

    const { files } = event.dataTransfer
    if (!files || !files[0]) return

    const file = files[0]

    // prevent further tip-tap drop event
    if (!isAcceptTypeValid(file.type, [FileTypeEnum.Image, FileTypeEnum.ImageOther])) return true

    onUpload({
      file,
      view,
      clientX: event.clientX,
      clientY: event.clientY,
    })

    // prevent further tip-tap drop event
    return true
  }
  return { onDrop, onPaste }
}
