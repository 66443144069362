import { DOMOutputSpec } from '@tiptap/pm/model'
import { mergeAttributes } from '@tiptap/react'
import { CSSProperties } from 'react'
import { convertStyleObjectToCss } from 'shared/utils/style-helpers'
import CustomImage from '../components/custom-image'
import {
  BLOCK_IMAGE_STYLES,
  FIGCAPTION_STYLES,
  FIGURE_CENTERED_STYLES,
  FIGURE_RIGHT_STYLES,
} from '../constans/default-styles'
import { ImageModeEnum, ImageNameEnum } from '../enums/image-enum'
import { getContainerWidthFromElement } from '../utils/get-container-width-from-element'
import { getImageAttrs } from '../utils/get-image-attrs'
import { getImageWithLinkHtml } from '../utils/get-image-with-link-html'
import { ReactNodeViewRenderer } from '../utils/react-node-view-renderer'
import { Image } from './image'

export const BlockImage = Image.extend({
  name: ImageNameEnum.Block,
  addNodeView() {
    return ReactNodeViewRenderer(CustomImage)
  },
  parseHTML() {
    return [
      {
        tag: 'figure',
        getAttrs: figureElement => {
          const imageElement = figureElement.querySelector('img')
          const linkElement = figureElement.querySelector('a')
          const figcaptionElement = figureElement.querySelector('figcaption')

          const href = linkElement?.getAttribute('href')

          const imageAttrs = getImageAttrs(imageElement)

          const containerWidth = getContainerWidthFromElement(figureElement)

          return {
            imageMode: figureElement?.style.float
              ? ImageModeEnum.BlockRight
              : ImageModeEnum.BlockCenter,

            href,
            containerWidth,
            caption: figcaptionElement?.textContent,

            ...imageAttrs,
          }
        },
      },
    ]
  },
  renderHTML({
    HTMLAttributes: {
      caption,
      href,
      imageMode,
      alt,
      src,
      title,
      width,
      height,
      containerWidth,
      uploadingProps,
    },
  }) {
    //skip unuploaded images
    if (uploadingProps) return ['span', { style: 'display: none;' }]

    const imageStyles: CSSProperties = { ...BLOCK_IMAGE_STYLES }

    if (width && height) {
      imageStyles.aspectRatio = `${width}/${height}`
    }

    if (containerWidth) {
      imageStyles.width = containerWidth
    }

    const attrs = mergeAttributes({
      src,
      alt,
      title,
      width,
      style: convertStyleObjectToCss(imageStyles),
    })

    const imageWithLink = getImageWithLinkHtml(attrs, { href })

    const elements: DOMOutputSpec[] = [imageWithLink]

    if (caption) {
      elements.push([
        'figcaption',
        {
          style: convertStyleObjectToCss(FIGCAPTION_STYLES),
        },
        caption,
      ])
    }

    const figureStyles =
      imageMode === ImageModeEnum.BlockCenter
        ? convertStyleObjectToCss({
            ...FIGURE_CENTERED_STYLES,
            ...(containerWidth && { width: containerWidth }),
          })
        : convertStyleObjectToCss({
            ...FIGURE_RIGHT_STYLES,
            ...(containerWidth && { width: containerWidth }),
          })

    return [
      'figure',
      {
        style: figureStyles,
      },
      ...elements,
    ]
  },
})
