import { NeutralButton, PrimaryButton, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import React, { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import LinkIcon from '../../icons/link-icon'
import { isImageSelected } from '../../utils/is-image-selected'
import { isCursorInsideLink } from '../../utils/link'

interface Props {
  className?: string
  shouldHideTooltip?: boolean
  editor: Editor | null
}

const LinkButton: FC<Props> = ({ className, shouldHideTooltip, editor }) => {
  const { t } = useLocoTranslation()

  const isActive = isCursorInsideLink(editor)

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  const setLink = (e: React.MouseEvent) => {
    if (!editor) return null

    if (editor.isActive('link')) {
      editor.chain().focus()
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: '' }).run()
    }
  }

  const Button = isActive ? PrimaryButton : NeutralButton

  return (
    <Tooltip
      disabled={shouldHideTooltip}
      label={t('dashboard.simple_mail_editor.link')}
      {...TOOLTIP_STYLES}
    >
      <Button
        type="button"
        disabled={isDisabled}
        className={twMerge(className, 'w-[48px]')}
        onClick={setLink}
      >
        <LinkIcon className={twMerge(isActive && 'fill-white', isDisabled && 'fill-gray')} />
      </Button>
    </Tooltip>
  )
}

export default LinkButton
