import { mergeAttributes } from '@tiptap/react'
import { CSSProperties } from 'react'
import { convertStyleObjectToCss } from 'shared/utils/style-helpers'
import CustomImage from '../components/custom-image'
import { ImageModeEnum, ImageNameEnum } from '../enums/image-enum'
import { getContainerWidthFromElement } from '../utils/get-container-width-from-element'
import { getImageAttrs } from '../utils/get-image-attrs'
import { getImageWithLinkHtml } from '../utils/get-image-with-link-html'
import { ReactNodeViewRenderer } from '../utils/react-node-view-renderer'
import { Image } from './image'

export const InlineImage = Image.configure({ inline: true }).extend({
  name: ImageNameEnum.Inline,
  inline: true,
  addNodeView() {
    return ReactNodeViewRenderer(CustomImage)
  },
  parseHTML() {
    return [
      {
        tag: 'p > img, p > a > img',
        getAttrs: imageElement => {
          const linkElement = imageElement.closest('a')

          const href = linkElement?.getAttribute('href')

          const imageAttrs = getImageAttrs(imageElement as HTMLImageElement)

          const containerWidth = getContainerWidthFromElement(imageElement)

          return {
            imageMode: ImageModeEnum.Inline,
            ...imageAttrs,
            href,
            containerWidth,
          }
        },
      },
    ]
  },
  renderHTML({ HTMLAttributes: { href, alt, title, width, height, containerWidth, src } }) {
    const imageStyles: CSSProperties = {}

    if (width && height) {
      imageStyles.aspectRatio = `${width}/${height}`
    }

    if (containerWidth) {
      imageStyles.width = containerWidth
    }

    const attrs = mergeAttributes({
      src,
      alt,
      title,
      width,
      style: convertStyleObjectToCss(imageStyles),
    })

    return getImageWithLinkHtml(attrs, { href })
  },
})
