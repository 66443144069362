import { CSSProperties } from 'react'

export const DEFAULT_COLOR = '#000000'
export const DEFAULT_FONT_SIZE = '15px'
export const DEFAULT_BG_COLOR = '#ffffff'
const DEFAULT_MY = '1rem'
export const EDITOR_PADDING = '8px'
export const EDITOR_BORDER = '1px'

export const PARAGRAPH_STYLES: CSSProperties = {
  marginTop: DEFAULT_MY,
  marginBottom: DEFAULT_MY,
  fontSize: DEFAULT_FONT_SIZE,
}

export const HEADING_STYLES: Record<number, CSSProperties> = {
  2: {
    marginTop: '0.83em',
    marginBottom: '0.83em',
    fontSize: '1.5em',
    lineHeight: 'normal',
    fontFamily: 'inherit',
    fontWeight: 'bold',
  },
  3: {
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: '1.17em',
    lineHeight: 'normal',
    fontFamily: 'inherit',
    fontWeight: 'bold',
  },
  4: {
    marginTop: '1.33em',
    marginBottom: '1.33em',
    lineHeight: 'normal',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    fontSize: '1em',
  },
}

export const LIST_STYLES: CSSProperties = {
  marginTop: DEFAULT_MY,
  marginBottom: DEFAULT_MY,
  paddingLeft: '40px',
}

export const LINK_STYLES = {
  textDecorationLine: 'underline',
  color: 'rgb(17 85 204)',
}

export const TOOLTIP_STYLES = {
  hoverOpenDelay: 900,
  popoverClassName: 'px-2 py-1 shadow-md [--anchor-gap:5px] z-[9999]',
}

export const FIGURE_CENTERED_STYLES: CSSProperties = {
  display: 'table',
  clear: 'both',
  textAlign: 'center',
  minWidth: '50px',
  maxWidth: '100%',
  margin: '.9em auto',
}

export const FIGURE_RIGHT_STYLES: CSSProperties = {
  display: 'table',
  float: 'right',
  clear: 'both',
  textAlign: 'center',
  minWidth: '50px',
  maxWidth: '100%',
  margin: '0 0 .9em 1.5em',
}

export const BLOCK_IMAGE_STYLES: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  minWidth: '100%',
  maxWidth: '100%',
}

export const FIGCAPTION_STYLES: CSSProperties = {
  display: 'table-caption',
  captionSide: 'bottom',
  wordBreak: 'break-word',
  textAlign: 'center',
  color: '#333',
  backgroundColor: '#f7f7f7',
  padding: '.6em',
  fontSize: '.75em',
  outlineOffset: '-1px',
}
