import { NodeViewProps } from '@tiptap/core'
import { NodeViewContent } from '@tiptap/react'
import { FC, useEffect, useState } from 'react'
import { PARAGRAPH_STYLES } from '../constans/default-styles'

export const PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE = 'data-inside-list-item'

const CustomParagraph: FC<NodeViewProps> = ({ getPos, editor, updateAttributes, node }) => {
  const [isInsideListItem, setIsInsideListItem] = useState(
    node.attrs[PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE],
  )

  const { textAlign, indent } = node.attrs

  useEffect(() => {
    const pos = getPos()
    const $pos = editor.state.doc.resolve(pos)

    const parentNode = $pos.parent
    if (parentNode.type.name === 'listItem') {
      setIsInsideListItem(true)
      setTimeout(() => {
        updateAttributes({
          [PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE]: true,
        })
      })
    } else {
      setIsInsideListItem(false)
      setTimeout(() => {
        updateAttributes({
          [PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE]: false,
        })
      })
    }
  }, [getPos, editor, updateAttributes])

  return (
    <p
      data-node-view-wrapper=""
      style={{
        ...PARAGRAPH_STYLES,
        ...(isInsideListItem ? { marginTop: '0', marginBottom: '0' } : {}),
        textAlign,
        marginLeft: `${indent}px`,
      }}
    >
      <NodeViewContent as="span" />
    </p>
  )
}

export default CustomParagraph
