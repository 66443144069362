import { NodeViewProps } from '@tiptap/core'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import { FC, useEffect } from 'react'
import { LIST_STYLES } from '../constans/default-styles'
import { useGetParentCount } from '../hooks/use-get-parent-count'
import { getOrderedListStyleType } from '../utils/list'

const CustomOrderedList: FC<NodeViewProps> = ({ getPos, editor, updateAttributes }) => {
  const { realDepth } = useGetParentCount({ editor, getPos, nodeName: 'orderedList' })

  useEffect(() => {
    setTimeout(() => {
      updateAttributes({ depth: realDepth })
    })
  }, [realDepth, updateAttributes])

  return (
    <NodeViewWrapper
      as={'ol'}
      style={{
        ...LIST_STYLES,
        listStyleType: getOrderedListStyleType(realDepth),
      }}
    >
      <NodeViewContent />
    </NodeViewWrapper>
  )
}

export default CustomOrderedList
