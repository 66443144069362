import { BinIcon, ColorPicker, NeutralButton, NeutralTypeEnum, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { DEFAULT_COLOR, TOOLTIP_STYLES } from '../../constans/default-styles'
import PaletteIcon from '../../icons/palette-icon'
import { isImageSelected } from '../../utils/is-image-selected'

interface Props {
  className?: string
  shouldHideTooltip?: boolean
  editor: Editor | null
}

const TextColorButton: FC<Props> = ({ className, shouldHideTooltip, editor }) => {
  const { t } = useLocoTranslation()

  const onChangeTextColor = (color: string) => {
    if (!editor) return null

    editor.chain().setColor(color).run()
  }

  const unSetTextColor = () => {
    if (!editor) return null

    editor.chain().focus().unsetColor().run()
  }

  const color = editor?.getAttributes('textStyle').color || DEFAULT_COLOR

  const hasAppliedColor = !!editor?.getAttributes('textStyle').color

  const isDisabled = !editor?.options.editable || isImageSelected(editor)

  return (
    <div className={className}>
      {!isDisabled ? (
        <ColorPicker
          additionalPopoverChildren={
            <NeutralButton
              disabled={!hasAppliedColor}
              className={'w-fit self-center'}
              neutralType={NeutralTypeEnum.danger}
              onClick={unSetTextColor}
            >
              <BinIcon
                className={twMerge(
                  !hasAppliedColor ? 'fill-darkblue/50' : 'fill-darkblue group-hover:fill-danger',
                  'main-transition-colors w-4 h-4',
                )}
              />
            </NeutralButton>
          }
          color={color}
          onChange={onChangeTextColor}
        >
          {open => (
            <Tooltip
              disabled={shouldHideTooltip}
              label={t('dashboard.simple_mail_editor.font_color')}
              {...TOOLTIP_STYLES}
            >
              <div
                className={twMerge(
                  'inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium rounded-lg min-h-[42px] w-[48px]',
                  open
                    ? 'text-white bg-blue hover:bg-blue-300'
                    : 'text-darkblue bg-white hover:bg-white border border-gray/30 hover:border-blue hover:text-blue',
                )}
              >
                <PaletteIcon
                  style={{
                    fill:
                      color.toString().toLowerCase() !== '#ffffff'
                        ? color
                        : open
                        ? 'white'
                        : 'black',
                  }}
                />
              </div>
            </Tooltip>
          )}
        </ColorPicker>
      ) : (
        <NeutralButton type="button" disabled={isDisabled}>
          <PaletteIcon className={twMerge(isDisabled && 'fill-gray')} />
        </NeutralButton>
      )}
    </div>
  )
}

export default TextColorButton
