import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import { Dropcursor } from '@tiptap/extension-dropcursor'
import FontFamily from '@tiptap/extension-font-family'
import { HardBreak } from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'
import { convertStyleObjectToCss } from 'shared/utils/style-helpers'
import CustomBulletListComponent from '../components/custom-bullet-list'
import CustomOrderedListComponent from '../components/custom-ordered-list'
import CustomParagraph, {
  PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE,
} from '../components/custom-paragraph'
import { defaultExtensions } from '../constans/default-extensions'
import {
  HEADING_STYLES,
  LINK_STYLES,
  LIST_STYLES,
  PARAGRAPH_STYLES,
} from '../constans/default-styles'
import { EditorExtensionEnum } from '../enums/editor-extensions-enum'
import { BackgroundColor } from '../extentions/background-color'
import { BlockImage } from '../extentions/block-image'
import { FontSize } from '../extentions/font-size'
import { Indent } from '../extentions/indent'
import { InlineImage } from '../extentions/inline-image'
import { getBulletListStyleType, getOrderedListStyleType } from './list'

export const getExtensions = (dependencies: EditorExtensionEnum[]) => {
  const extensionMap = {
    [EditorExtensionEnum.Document]: Document,
    [EditorExtensionEnum.Paragraph]: Paragraph.extend({
      addAttributes() {
        return {
          [PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE]: {
            type: 'boolean',
            default: false,
          },
        }
      },
      addNodeView() {
        return ReactNodeViewRenderer(CustomParagraph)
      },
      renderHTML({ HTMLAttributes }) {
        const { [PARAGRAPH_INSIDE_LIST_ITEM_ATTRIBUTE]: insideListItem, ...rest } = HTMLAttributes

        return [
          'p',
          mergeAttributes(rest, {
            style: convertStyleObjectToCss({
              ...PARAGRAPH_STYLES,
              ...(insideListItem ? { marginTop: '0', marginBottom: '0' } : {}),
            }),
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.Text]: Text,
    [EditorExtensionEnum.TextStyle]: TextStyle,
    [EditorExtensionEnum.FontFamily]: FontFamily.configure({
      types: ['textStyle'],
    }),
    [EditorExtensionEnum.Heading]: Heading.extend({
      renderHTML({ node, HTMLAttributes }) {
        const allowedLevels = [2, 3, 4]
        const level = allowedLevels.includes(node.attrs.level) ? node.attrs.level : 2

        return [
          `h${level}`,
          mergeAttributes(HTMLAttributes, {
            style: convertStyleObjectToCss(HEADING_STYLES[level]),
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.Bold]: Bold,
    [EditorExtensionEnum.Italic]: Italic,
    [EditorExtensionEnum.Strike]: Strike,
    [EditorExtensionEnum.Underline]: Underline,
    [EditorExtensionEnum.BulletList]: BulletList.extend({
      addAttributes() {
        return {
          depth: {
            default: 0,
          },
        }
      },
      addNodeView() {
        return ReactNodeViewRenderer(CustomBulletListComponent)
      },
      renderHTML({ HTMLAttributes }) {
        const { depth } = HTMLAttributes

        return [
          'ul',
          mergeAttributes(HTMLAttributes, {
            style: convertStyleObjectToCss({
              ...LIST_STYLES,
              listStyleType: getBulletListStyleType(depth),
            }),
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.OrderedList]: OrderedList.extend({
      addAttributes() {
        return {
          depth: {
            default: 0,
          },
        }
      },
      addNodeView() {
        return ReactNodeViewRenderer(CustomOrderedListComponent)
      },
      renderHTML({ HTMLAttributes }) {
        const { depth } = HTMLAttributes

        return [
          'ol',
          mergeAttributes(HTMLAttributes, {
            style: convertStyleObjectToCss({
              ...LIST_STYLES,
              listStyleType: getOrderedListStyleType(depth),
            }),
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.ListItem]: ListItem.extend({
      content: 'block*',
    }),
    [EditorExtensionEnum.TextAlign]: TextAlign.configure({
      types: ['heading', 'paragraph', 'listItem'],
      alignments: ['left', 'center', 'right', 'justify'],
    }),
    [EditorExtensionEnum.Link]: Link.extend({
      renderHTML({ HTMLAttributes }) {
        return [
          'a',
          mergeAttributes(HTMLAttributes, {
            style: convertStyleObjectToCss(LINK_STYLES),
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.History]: History,
    [EditorExtensionEnum.Color]: Color,
    [EditorExtensionEnum.BackgroundColor]: BackgroundColor,
    [EditorExtensionEnum.FontSize]: FontSize,
    [EditorExtensionEnum.Indent]: Indent,
    [EditorExtensionEnum.HardBreak]: HardBreak,
    [EditorExtensionEnum.Substitution]: null,
    [EditorExtensionEnum.SpecialCharacters]: null,
    [EditorExtensionEnum.Image]: [BlockImage, InlineImage],
    [EditorExtensionEnum.DropCursor]: Dropcursor,
  }

  return dependencies
    .flatMap(dep => {
      const extension = extensionMap[dep]
      return Array.isArray(extension) ? extension : [extension]
    })
    .filter((e): e is NonNullable<typeof e> => e !== null)
}

export const getExistingExtensions = (
  extensions: EditorExtensionEnum[],
): { [K in keyof typeof EditorExtensionEnum as `has${K}`]: boolean } =>
  defaultExtensions.reduce(
    (acc, key) => ({
      ...acc,
      [`has${EditorExtensionEnum[key]}`]: extensions.includes(key),
    }),
    {} as { [K in keyof typeof EditorExtensionEnum as `has${K}`]: boolean },
  )
