import {
  DataFileInterface,
  FileSelectorModal,
  FileSourceEnum,
  FileTypeEnum,
  QueryClientProvider,
} from '@systemeio/file-manager'
import { NeutralButton, Tooltip } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twJoin } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { ImageModeEnum } from '../../enums/image-enum'
import ImageIcon from '../../icons/image-icon'

const ImageButton = ({ editor }: { editor: Editor | null }) => {
  const { t } = useLocoTranslation()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleChangeModalVisibility = () => {
    setIsOpenModal(prevState => !prevState)
  }

  const isDisabled = !editor?.options.editable

  const handleSetFile = (file: DataFileInterface) => {
    if (!editor) return null

    const img = new Image()

    img.src = file.path

    img.onload = () => {
      const width = img.naturalWidth
      const height = img.naturalHeight

      editor
        .chain()
        .focus()
        .setImage({
          src: file.path,
          imageMode: ImageModeEnum.BlockCenter,
          containerWidth: 'auto',
          width,
          height,
        })
        .run()

      handleChangeModalVisibility()
    }
  }

  return (
    <>
      <Tooltip label={t('dashboard.simple_mail_editor.image')} {...TOOLTIP_STYLES}>
        <NeutralButton type="button" disabled={isDisabled} onClick={handleChangeModalVisibility}>
          <ImageIcon className={twJoin(isDisabled && 'fill-gray')} />
        </NeutralButton>
      </Tooltip>
      <QueryClientProvider>
        <FileSelectorModal
          initialSearchFilters={{
            name: '',
            source: [],
            type: [FileTypeEnum.Image, FileTypeEnum.ImageOther],
          }}
          opened={isOpenModal}
          onClose={handleChangeModalVisibility}
          onSelect={handleSetFile}
          uploadingFromSource={FileSourceEnum.Ckeditor}
          disabledFileTypeSelect={true}
        />
      </QueryClientProvider>
    </>
  )
}

export default ImageButton
