import { BUTTON_BASE_CLASS_NAME, BUTTON_BASE_FLEX_CLASS_NAME } from '@systemeio/ui-shared'
import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface PluginControlContainerProps {
  disabled?: boolean
  className?: string
}

export function PluginControlContainer({
  disabled,
  children,
  className,
}: PropsWithChildren<PluginControlContainerProps>) {
  return (
    <div
      className={twMerge(
        BUTTON_BASE_FLEX_CLASS_NAME,
        BUTTON_BASE_CLASS_NAME,
        'border bg-white border-gray/30 hover:border-blue px-4',
        disabled && 'bg-gray/50 pointer-events-none',
        className,
      )}
    >
      {children}
    </div>
  )
}
