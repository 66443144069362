import { NeutralButton, Tooltip, UndoIcon } from '@systemeio/ui-shared'
import { Editor } from '@tiptap/react'
import { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { useShortcuts } from '../../hooks/use-shortcuts'

interface Props {
  className?: string
  editor: Editor | null
}

const HistoryButtons: FC<Props> = ({ className, editor }) => {
  const { getShortcut, rawShortcuts } = useShortcuts()
  const { t } = useLocoTranslation()

  const isUndoDisabled = !editor?.options.editable || !editor?.can().undo()
  const isRedoDisabled = !editor?.options.editable || !editor?.can().redo()

  return (
    <div className={twMerge('flex gap-1', className)}>
      <Tooltip
        label={`${t('dashboard.simple_mail_editor.undo')} (${getShortcut(
          rawShortcuts.history.undo,
        )})`}
        {...TOOLTIP_STYLES}
      >
        <NeutralButton
          type="button"
          onClick={() => editor?.chain().focus().undo().run()}
          disabled={isUndoDisabled}
        >
          <UndoIcon className={isUndoDisabled ? 'fill-gray' : 'fill-black'} />
        </NeutralButton>
      </Tooltip>
      <Tooltip
        label={`${t('dashboard.simple_mail_editor.redo')} (${getShortcut(
          rawShortcuts.history.redo,
        )})`}
        {...TOOLTIP_STYLES}
      >
        <NeutralButton
          type="button"
          onClick={() => editor?.chain().focus().redo().run()}
          disabled={isRedoDisabled}
        >
          <UndoIcon
            className={twMerge(
              'transform -scale-x-100',
              isRedoDisabled ? 'fill-gray' : 'fill-black',
            )}
          />
        </NeutralButton>
      </Tooltip>
    </div>
  )
}

export default HistoryButtons
