export type OSType = 'macOS' | 'Windows' | 'Linux'
export const getOS = (): OSType | undefined => {
  // SSR
  if (typeof window === 'undefined') return undefined

  if (navigator.userAgentData?.platform) {
    return navigator.userAgentData.platform as OSType
  }

  const userAgent = navigator.userAgent.toLowerCase()

  if (userAgent.includes('mac')) return 'macOS'
  if (userAgent.includes('win')) return 'Windows'
  if (userAgent.includes('linux')) return 'Linux'
}
